import topic from '../topic';
import { getNoCatch } from '@api/request';

export const dequAPI = {
  getDequs: topic.dequTopic + '/get_dequs',
  deleteDequByIds: topic.dequTopic + '/delete_dequ_by_ids',
  addDequ: topic.dequTopic + '/add_dequ',
  uploadDequDocuments: topic.dequTopic + '/upload_dequ_documents',
  getDequById: topic.dequTopic + '/get_dequ_by_id',
  editDequ: topic.dequTopic + '/edit_dequ',
  getAllDequsV1: topic.dequTopic + '/get_all_dequs_v1',
  getDequPterByDequId: topic.dequTopic + '/get_dequ_pter_by_dequ_id',
  get_dequ_pter_by_desi_ids: topic.requTopic + '/get_dequ_pter_by_desi_ids',
  getDequCountList: params => getNoCatch(`${topic.dequTopic}/getDequCountList`, params),
  getDequPterByDequIds: params => getNoCatch(`${topic.dequTopic}/get_dequ_pter_by_dequ_ids`, params),
  exportGetDequCount: params => getNoCatch(`${topic.dequTopic}/exportGetDequCount`, params)
};

import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const productDesignGroupAPI = {
  get_designTeams: params => getNoCatch(`${topic.productDesignGroupTopic}/get_designTeams`, params),
  add_designTeam: params => postNoCatch(`${topic.productDesignGroupTopic}/add_designTeam`, params),
  delete_designTeamIds: params => postNoCatch(`${topic.productDesignGroupTopic}/delete_designTeamIds`, params),
  get_designList: params => getNoCatch(`${topic.productDesignGroupTopic}/get_designList`, params),
  get_docuList: params => getNoCatch(`${topic.productDesignGroupTopic}/get_docuList`, params),
  get_designTeam_id: params => getNoCatch(`${topic.productDesignGroupTopic}/get_designTeam_id`, params),
  edit_designTeam: params => postNoCatch(`${topic.productDesignGroupTopic}/edit_designTeam`, params)
};

import store from '@/store';
import { getDate, getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '组合名称',
    prop: 'designTeam_name',
    itemType: 'input',
    input: true,
    sortable: true,
    widthAuto: true
  },
  { prop: 'stff_name', label: '录入人', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    input: false,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];

export const productDesignGroupMainFormProperties = [
  {
    label: '组合名称',
    prop: 'designTeam_name',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  }
];

export const productDesignGroupSubTableProperties = [
  {
    label: '设计单号',
    prop: 'desi_no',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '产品名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '产品类别',
    prop: 'prod_type',
    itemType: 'input',
    input: false,
    sortable: false,
    subItem: {
      type: 'newSelect',
      options: {},
      disabled: true
    }
  },
  {
    label: '设计人',
    prop: 'desi_stff_name',
    itemType: 'input',
    input: false,
    sortable: false
  }
];
export const productDesignGroupFileSubTableProperties = [
  {
    label: '文件名称',
    prop: 'docu_name',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '文件格式',
    prop: 'docu_suffix',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '文件大小',
    prop: 'docu_size',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '上传人',
    prop: 'uploaded_personnel',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '上传日期',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => getDate(val, true)
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    sortable: false
  }
];
export const productDesignGroupDialog1 = [
  {
    label: '设计单号',
    prop: 'desi_no',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '产品名称',
    prop: 'prod_name',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '产品类别',
    prop: 'prod_type',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    subItem: {
      type: 'newSelect',
      options: {},
      disabled: true
    }
  },
  {
    label: '设计人',
    prop: 'desi_stff_name',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '录入时间',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => getDateNoTime(val, true)
  }
];
export const productDesignGroupDialog2 = [
  {
    label: '文件名称',
    prop: 'docu_name',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '文件格式',
    prop: 'docu_suffix',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '文件大小',
    prop: 'docu_size',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '上传人',
    prop: 'uploaded_personnel',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '上传日期',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => getDate(val, true)
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    labelWidth: 150,
    input: false,
    sortable: false
  }
];
export const imageArr = [
  'bmp',
  'jpg',
  'png',
  'tif',
  'gif',
  'pcx',
  'tga',
  'exif',
  'fpx',
  'svg',
  'psd',
  'cdr',
  'pcd',
  'dxf',
  'ufo',
  'eps',
  'ai',
  'raw',
  'WMF',
  'webp',
  'avif',
  'apng',
  'jpeg'
];
export const officeFileSuffixList = [
  'doc',
  'docx',
  'docm',
  'dotm',
  'dotx',
  'xlsx',
  'xlsb',
  'xls',
  'xlsm',
  'pptx',
  'ppsx',
  'ppt',
  'pps',
  'pptm',
  'potm',
  'ppam',
  'potx',
  'ppsm'
];

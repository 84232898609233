<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane :key="'first'" label="单据信息" name="first">
        <childTab1 ref="childTab1" @isShow="isShow" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProductDesignGroupAddMain from '@/views/DevelopManagement/ProductDesignGroupManage/TabChild/ProductDesignGroupAddMain.vue';

export default {
  name: 'InformEdit',
  components: {
    childTab1: ProductDesignGroupAddMain
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
